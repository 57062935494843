<template>
	<main class="main">
    <div class="container container--small">
      <Crumbs :data="crumbs"></Crumbs>
    </div>
		<section class="main__box" v-if="blocks">
			<div class="container container--small">
				<h1 class="main__title">{{ blocks[0].title }}</h1>
				<div class="main__text editor" v-html="blocks[0].content"></div>
				<ContactsList
					:data="[
						{
							title: 'Отдел по работе с акцептантами',
							list: [blocks[1], blocks[2]],
						},
						{
							title: 'Взаимодействие со СМИ',
							list: [blocks[3], blocks[4]],
						},
					]"
				></ContactsList>
			</div>
		</section>

		<div class="bg bg--gray last" v-if="blocks">
			<section class="main__box main__box--big">
				<div class="container container--small">
					<div
						class="main__text main__text--big main__text--center text-normal editor link-no-underline"
						v-html="blocks[5].content"
					></div>
				</div>
			</section>
		</div>
	</main>
</template>

<script>
import ContactsList from '@/components/ContactsList.vue'
import Crumbs from '@/components/Crumbs'

export default {
	name: 'ContactsPartner',

	components: {
		ContactsList,
		Crumbs,
	},

	data() {
		return {
			contacts: null,
			crumbs: null,
		}
	},

	computed: {
		blocks() {
			const blocks = this.$store.getters.BLOCKS

			return blocks[this.$options.name]
		},
	},

	mounted() {
		this.$store.dispatch('GET_BLOCKS', {
			page: this.$route.fullPath,
			block: this.$options.name,
		})

		this.contacts = [
			{
				title: 'Отдел по работе с акцептантами',
				list: [
					{
						name: 'Юрлова Антонина Альбертовна',
						phone: '8 (343) 379 08 86 (вн. 110)',
						mail: 'yurlova_aa@eskso.ru',
					},
					{
						name: 'Трошкин Андрей Арнольдович',
						phone: '8 (343) 379 08 86 (вн. 111)',
						mail: 'troshkin_aa@eskso.ru',
					},
				],
			},
			{
				title: 'Взаимодействие со СМИ',
				list: [
					{
						name: 'Пузырева Наталья Геннадьевна',
						phone: '8 (343) 379 08 86 (вн. 120)',
						mail: 'ng@eskso.ru',
					},
					{
						name: 'Воробьева Ольга Николаевна',
						phone: '8 (343) 379 08 86 (вн. 121)',
						mail: 'vorobeva_on@eskso.ru',
					},
				],
			},
		]

    this.crumbs = [
      {
        link: 'PartnerHome',
        text: 'Главная',
      },
      {
        link: null,
        text: 'Контакты',
      },
    ]
	},
}
</script>
