var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"main"},[_c('div',{staticClass:"container container--small"},[_c('Crumbs',{attrs:{"data":_vm.crumbs}})],1),(_vm.blocks)?_c('section',{staticClass:"main__box"},[_c('div',{staticClass:"container container--small"},[_c('h1',{staticClass:"main__title"},[_vm._v(_vm._s(_vm.blocks[0].title))]),_c('div',{staticClass:"main__text editor",domProps:{"innerHTML":_vm._s(_vm.blocks[0].content)}}),_c('ContactsList',{attrs:{"data":[
						{
							title: 'Отдел по работе с акцептантами',
							list: [_vm.blocks[1], _vm.blocks[2]],
						},
						{
							title: 'Взаимодействие со СМИ',
							list: [_vm.blocks[3], _vm.blocks[4]],
						} ]}})],1)]):_vm._e(),(_vm.blocks)?_c('div',{staticClass:"bg bg--gray last"},[_c('section',{staticClass:"main__box main__box--big"},[_c('div',{staticClass:"container container--small"},[_c('div',{staticClass:"main__text main__text--big main__text--center text-normal editor link-no-underline",domProps:{"innerHTML":_vm._s(_vm.blocks[5].content)}})])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }